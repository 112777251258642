:root {
  --color-first: #0d0d0f;
  --color-second: #242424;
  --color-third: #4e4e4e;

  --selection-color: #14f195;
}

html {
  background-color: var(--color-first);
}


.btn-simple {
  padding: 0;
  margin: 0;
  background: transparent;
  color: white;
  border: none;
}
.btn-default {
  background: var(--color-second);
  color: white;
  border: none;
}
.btn-default-reversed {
  background: white;
  color: black;
  border: none;
}

.input-simple {
  border: none!important;
  box-shadow: none!important;
}
.input-simple:focus {
  outline: none!important;
}
.input-dark {
  background: black;
  color: white;
}

.hover-pointer:hover {
  cursor: pointer;
}
.hover-transparent {
  transition: all 0.2s;
}
.hover-transparent:hover {
  opacity: .8;
}


.title {
  font-size: 4rem;
}
.text-luxury {
  font-family: "Cinzel Deco Reg";
}
.text-bold {
  font-weight: 600;
}
.text-no-decoration {
  text-decoration: none;
}

.text-third {
  color: var(--color-third);
}


.bg-first {
  background: var(--color-first);
}


.photobanner {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  white-space: nowrap;
  z-index: 0;
  width: 100%;
  height: 100%;
}

#music-player {
  display: none
}
#music-controller {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background: rgba(0, 0, 0, 0.6);
  padding: .3rem;
  z-index: 1000;
}
#music-sound-range {
  width: 10px;
  height: 100px;
  /* background: #33ffaa; */
  /* border-radius: 2rem; */
  margin: auto;
  margin-bottom: 1rem;
}
#music-sound-range .range-slider {
  width: 10px;
  height: 100px;
  border-radius: 2rem;
  overflow: hidden;
}
#music-sound-range .range-slider .range-slider__thumb {
  display: none;
}
#music-sound-range .range-slider .range-slider__range {
  height: 100%!important;
}



#music-controller img {
  width: 50px;
  height: 50px;

  transition: all .3s;
}
#music-controller img:hover {
  transform: scale(1.1);
  opacity: .8;
}




.loading-square {
  background: var(--color-second);
  height: 30px;
  margin: 0 auto;
  opacity: .85;
  border-radius: .2rem;
}