.btn-primary {
    background: white!important;
    color: black !important;
    border: none !important;
}
.btn-secondary {
    background: black!important;
    color: white !important;
    border: none !important;
}

.spinner-border {
    color: white;
    width: 50px!important;
    height: 50px!important;
}


.form-select.dark-select {
    background-color: var(--color-first);
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23ffffff%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e");
    color: white;
    border: none!important;
    box-shadow: none!important;
}


.idui-popover {
    padding: .3rem .8rem!important;
    font-size: .9rem!important;
}