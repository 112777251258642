#build-wolf {
    background: var(--color-first);
    padding-bottom: 10rem;
}

#build-image {
    width: 400px;
    height: 400px;
}
@media screen and (max-width: 500px) {
    #build-image {
        width: 300px;
        height: 300px;
    }
}

#build-image-border-top-left {
    position: absolute;
    top: -10px;
    left: -10px;
    width: 30px;
    height: 30px;
    border-radius: .2rem;
    border-top: 1px solid white;
    border-left: 1px solid white;
}
#build-image-border-top-right {
    position: absolute;
    top: -10px;
    right: -10px;
    width: 30px;
    height: 30px;
    border-radius: .2rem;
    border-top: 1px solid white;
    border-right: 1px solid white;
}

#build-image-border-bottom-left {
    position: absolute;
    bottom: -10px;
    left: -10px;
    width: 30px;
    height: 30px;
    border-radius: .2rem;
    border-bottom: 1px solid white;
    border-left: 1px solid white;
}
#build-image-border-bottom-right {
    position: absolute;
    bottom: -10px;
    right: -10px;
    width: 30px;
    height: 30px;
    border-radius: .2rem;
    border-bottom: 1px solid white;
    border-right: 1px solid white;
}


#randomize-traits-dice {
    position: relative;
    margin-top: 7.5rem;
    width: 60px;
    height: 60px;
    
}
#randomize-traits-dice div {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 50%;
    border-top: 2px solid white;
    border-bottom: 2px solid white;
    transition: .4s;
}
#randomize-traits-dice:hover div {
    transform: rotate(180deg);
}