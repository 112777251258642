#connect-wallet-modal .modal-content {
    width: fit-content;
    background: var(--color-first);
    color: white;
    padding: 2rem 1rem;
    margin: 0 auto;
}



.wallet-adapter-option {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1rem 4rem;
    filter: brightness(.75);

    transition: all .4s;
}

.wallet-adapter-option img {
    width: 30px;
    height: 30px;
    margin-right: 1rem;

    transition: all .4s;
}
.wallet-adapter-option h6 {
    font-size: inherit;
}

.wallet-adapter-option:hover {
    filter: brightness(1);
}
/* .wallet-adapter-option:hover img {
    width: 35px;
    height: 35px;
} */