#navbar {
    position: sticky;
    top: 0;
    padding-top: 1rem;
    z-index: 1000;
    background: var(--color-first);
}
#navbar.transparent {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding-top: 0;
    background: transparent;
}

#navbar .logo-img, #navbar-overlay .logo-img {
    width: 65px;
    height: 65px;
}

#navbar-overlay {
    background: var(--color-first);
}

#navbar-overlay .btn-close {
    background: transparent url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23fff%27%3e%3cpath d=%27M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z%27/%3e%3c/svg%3e") center/1em auto no-repeat;
}
#navbar-overlay .btn-close:focus {
    box-shadow: none;
}