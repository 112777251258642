.Toastify__toast {
    background: #000000!important;
    color: #ffffff!important;
    font-family: 'Cinzel Deco Reg'!important;
    text-align: center!important;
    border-radius: 0!important;
}

.Toastify__toast-icon, .Toastify__close-button {
    display: none !important;
}