.connect-wallet-btn {
    width: 100%;
    color: black;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: .5rem 1rem;
}

.connect-wallet-btn img {
    width: 20px;
    height: 20px;
    filter: invert(100%);
    margin-right: 1rem;

    transition: all .4s;
}

.connect-wallet-btn:not(.disabled):hover img {
    width: 25px;
    height: 25px;
}

.connect-wallet-btn.disabled {
    opacity: .3;
}