#mint-container {
    width: 100%;
    height: 100vh;
    background: var(--color-first);
    /* padding-top: 1rem; */
}

#mint-container #video-container {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    max-height: 100%;
    width: 100vw;
    overflow: hidden;
}
#mint-container #video-container video {
    height: 110%;
}

#mint-container #mint-info {
    position: relative;
    background: var(--color-first);
    opacity: .95;
    padding: 3rem 1.5rem 1rem;
    margin: 0 8rem;
    max-width: 400px;
    z-index: 10;
}
@media screen and (max-width: 654px) {
    #mint-container #mint-info {margin: 0 auto;}
}

#mint-info #mint-referral-input {
    background: var(--color-third);
    font-size: 1.5rem;
    color: white;
}


#mint-info #minting-hider {
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
}



#mint-results-modal .modal-content {
    background: var(--color-first);
    color: white;
}

.minted-nft-card {
    position: relative;
    width: 200px;
    height: auto;
    background: var(--color-first);

    /* z-index: 100; */
    transition: all .4s;
}

.btn-default.twitter-login-btn {
    background: #00acee!important;
    color: white!important;
}