#brokers-grove {
    width: 100%;
    min-height: 100%;
    background: var(--color-first);

    padding-bottom: 10rem;
    /* padding-top: 1rem; */
}

input#broker-search {
    background: var(--color-second);
    color: white;
    font-size: 1.5rem;
    border-radius: .75rem;
    padding-right: 50px;
}

#brokers-leaderboard {
    color: white;
}
#brokers-leaderboard th, #brokers-leaderboard td {
    text-align: center;
}
#brokers-leaderboard th {
    width: 200px;
}
#brokers-leaderboard td img {
    width: 50px;
    height: 50px;
}

#brokers-leaderboard tbody tr {
    height: 100px;
}

/* .data-circle {
    width: 50px;
    height: 50px;
    border: 5px solid red;
    border-radius: 50%;
    
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
}
.data-circle.blue {border-color: blue;}
.data-circle.purple {border-color: purple;} */

#brokers-tree {
    margin-top: 5rem;
    z-index: 10;
}

.ikCnnF, .iZkzWX {
    display: none!important;
}

.tree-branch-support {
    width: fit-content;
    height: 2px;
    background: white;

}

.tree-branch-extension {
    padding-left: 74px;
    padding-right: 74px;
    width: 2px;
    background: white;
}




#current-royalty-share-container {
    width: fit-content;
}





#total-brokers-levels-container h1 {
    /* color: grey; */
    font-size: 6rem;
    color: #e0dfdc;
    letter-spacing: .1em;
    text-shadow: 0 -1px 0 #fff, 0 1px 0 #2e2e2e, 0 2px 0 #2c2c2c, 0 3px 0 #2a2a2a, 0 4px 0 #282828, 0 5px 0 #262626, 0 6px 0 #242424, 0 7px 0 #222, 0 8px 0 #202020, 0 9px 0 #1e1e1e, 0 10px 0 #1c1c1c, 0 11px 0 #1a1a1a, 0 12px 0 #181818, 0 13px 0 #161616, 0 14px 0 #141414, 0 15px 0 #121212, 0 22px 30px rgba(0,0,0,0.9);
    transition: all .4s;
}
.total-brokers-levels-line h5 {
    margin: 0 3rem;
}