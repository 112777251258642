#enter-container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(20, 20, 20, 0.8);
    z-index: 10000;
    width: 100vw;
    height: 100vh;
    color: white;
    overflow: hidden;
}


#main-hero-container {
    position: relative;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}
#main-hero-container img {
    width: 100%;
    height: 100%;
}
#main-hero-container #navbar {
    width: 100%;
    height: 70px;
    /* background: var(--color-second); */
}