#marketplace {
    width: 100%;
    min-height: 100%;
    background: var(--color-first);
}

#collection-info-container {
    padding-top: 6.5rem;
}
@media screen and (min-width: 768px) {
    #collection-info-container {
        padding-left: 5rem;
        padding-right: 5rem;
    }
}

#collection-info-logo {
    width: 150px;
    height: 150px;
    border-radius: 50%;
}

#collection-market-stats {
    /* padding-left: 3rem; */
    margin-top: 3rem;
    margin-bottom: 1rem;
}
.market-stat-container {
    /* background: var(--color-second); */
    color: white;
    border-radius: .8rem;
    padding: .7rem 1rem;
}
.market-stat-container h6 {
    font-size: .8rem;
}
.market-stat-container p {
    font-size: .75rem;
}

#marketplace-view-selection-container {
    overflow-x: scroll;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
}
.marketplace-view-selection-option {
    width: max-content;
    background: var(--color-second);
    color: white;
    padding: .7rem 1rem;
    border-radius: .8rem;
    transition: all .4s;
}
.marketplace-view-selection-option.selected {
    background: white;
    color: black;
}
.marketplace-view-selection-option:hover {
    cursor: pointer;
    background: white;
    color: black;
}